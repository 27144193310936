import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Name from "../components/Name"

import * as S from '../styles/404';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description="Error 404" />
    <S.Container>
      <S.Main>
        <S.DivLogo>
          {/* <S.Logo>
            <Logo />
          </S.Logo> */}
          <S.Name>
            <Name />
          </S.Name>
        </S.DivLogo>
        <S.Separator />
        <S.Content>
          <S.DivTitle>
            <S.TextTitle>404</S.TextTitle>
            <S.TextTitleSub>Página não encontrada</S.TextTitleSub>
          </S.DivTitle>
          <S.DivContacts>
            <S.Text>Ops... não há nada aqui</S.Text>
            <S.TextLink to="/">Ir para HOME</S.TextLink>
          </S.DivContacts>
        </S.Content>
      </S.Main>
      {/* <S.Temp>
        <S.TextTitleSub>[ Site under development ]</S.TextTitleSub>
      </S.Temp> */}
    </S.Container>
  </Layout>
)

export default NotFoundPage

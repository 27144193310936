import { Link } from 'gatsby';
import styled from 'styled-components';
import media from 'styled-query';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  ${media.lessThan("1024px")`
    /* display: none; */
  `}
  /* ${media.between("768px", "999px")`
    font-size: 1.2vw;
  `}
  ${media.between("900px", "1150px")`
    font-size: 1.2vw;
  `} */
`;

export const Main = styled.div`
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${media.lessThan("849px")`
    flex-direction: column;
  `}
`;

export const Temp = styled.div`
  background: #0e1c26;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DivLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.div`
  width: 150px;
  ${media.lessThan("849px")`
    width: 100px;
  `}
`;

export const Name = styled.div`
  width: 450px;
  ${media.lessThan("849px")`
    width: 300px;
  `}
`;

export const Separator = styled.div`
  width: 5px;
  height: 250px;
  background: #21205d;
  border-radius: 5px;
  margin: 0 30px;

  ${media.lessThan("849px")`
    width: 300px;
    height: 5px;
    margin: 40px 0;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 270px;
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 30px;

  ${media.lessThan("849px")`
    align-items: center;
  `}
`;

export const TextTitle = styled.h1`
  color: #ed1e26;
  font-size: 30px;
`;

export const TextTitleSub = styled.h2`
  color: #292896;
  font-size: 25px;
`;

export const DivContacts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const Text = styled.h5`
  color: #292896;
  font-size: 16px;
`;

export const TextLink = styled(Link)`
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
  font-size: 20px;
  background-color: #292896;
  color: white;
  text-decoration: none;
  transition: background-color 0.5s;
  &:hover {
    background-color: #1fa1f2;
    svg {
      fill: #1fa1f2 !important;
      color: #1fa1f2 !important;
    }
  }
`;